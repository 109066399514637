import React from "react";
import { useForm } from "@mantine/form";
import { Button, Textarea } from "@mantine/core";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../Firebase";
import { showNotification, updateNotification } from "@mantine/notifications";
import { AiOutlineCheck } from "react-icons/ai";
import { HiOutlineX } from "react-icons/hi";
import StatesJSON from "../../data/states.json"

const AddBidEntry = () => {
  const bidForm = useForm({
    initialValues: {
      title: "",
      url: "",
      state: "",
      agency: "",
      open_date: "",
      close_date: "",
      description: "",
    },

    validate: {
      open_date: (value) =>
        /^\d{1,2}\/\d{1,2}\/\d{2,4}/.test(value) ? null : "Invalid date format",
      close_date: (value) =>
        /^\d{1,2}\/\d{1,2}\/\d{2,4}/.test(value) ? null : "Invalid date format",
    },
  });

  return (
    <div className="m-x-xs m-3">
      <form
        onSubmit={bidForm.onSubmit(async (data, values) => {
          try {
            // Uploading Logic
            if (bidForm.isDirty()) {
              showNotification({
                id: "upload",
                title: "Uploading bid...",
                color: "blue",
                loading: true,
              });

              let newClose = new Date(data.close_date);
              let newOpen = new Date(data.open_date);

              const collectionRef = collection(db, "bids");

              try {
                let todaysDate = new Date();

                const document = await addDoc(collectionRef, {
                  agency: data.agency,
                  close_date: data.close_date,
                  close_timestamp: Math.floor(newClose.getTime() / 1000),
                  creation_timestamp: Math.floor(todaysDate.getTime() / 1000),
                  description: data.description,
                  login_required: false,
                  open_date: data.open_date,
                  open_timestamp: Math.floor(newOpen.getTime() / 1000),
                  state_displayable: StatesJSON[data.state],
                  payment_required: false,
                  site_name: "BidBanana",
                  state: data.state,
                  title: data.title,
                  url: data.url,
                });

                updateNotification({
                  id: "upload",
                  title:
                    "Upload successful! Please wait a few seconds for the database to index the new bid.",
                  color: "green",
                  icon: <AiOutlineCheck size={16} />,
                  autoClose: 2000,
                });
              } catch (e) {
                updateNotification({
                  id: "upload",
                  title: "Upload failed",
                  color: "red",
                  icon: <HiOutlineX size={16} />,
                  autoClose: 2000,
                });
              }
            } else {
              showNotification({
                id: "no-changes",
                title: "Please edit a field in order to upload a bid.",
                icon: <HiOutlineX size={16} />,
                color: "red",
              });
            }
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <section className="m-2 p-2  border-1 rounded-xl">
          <article className="px-2">
            <h1 className="font-medium">Upload a bid</h1>
            <h2 className="text-justify">
              Input data into the fields, then hit the "Upload" button to upload
              the bid.
            </h2>
          </article>
        </section>
        <section className="m-2 p-2  border-1 rounded-xl">
          <article className="px-2">
            <Textarea
              mt="sm"
              mb="md"
              label="Title"
              autosize
              placeholder="RFP Name"
              required
              {...bidForm.getInputProps("title")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="URL"
              autosize
              placeholder="URL"
              required
              {...bidForm.getInputProps("url")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="Agency"
              autosize
              placeholder="Agency"
              required
              {...bidForm.getInputProps("agency")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="State (must be abbreviated)"
              autosize
              placeholder="State"
              required
              {...bidForm.getInputProps("state")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="Open Date"
              autosize
              placeholder="Open Date"
              required
              {...bidForm.getInputProps("open_date")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="Close Date"
              autosize
              placeholder="Close Date"
              required
              {...bidForm.getInputProps("close_date")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="Description"
              autosize
              placeholder="Description"
              required
              {...bidForm.getInputProps("description")}
            />
          </article>
        </section>
        <section className="m-2 p-1 flex justify-start mt-2">
          <Button
            variant="outline"
            size="sm"
            radius="xl"
            type="submit"
            className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
          >
            Upload
          </Button>
        </section>
      </form>
    </div>
  );
};

export default AddBidEntry;
